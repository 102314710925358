<template>
  <div class="proBoard" v-loading='loading'>
    <div class="board-query">
      <el-select placeholder="请选择仓库" class="no-boder-select" popper-class="no-border-select-popper"
                 v-model="formData.stockId">
        <el-option v-for="item in sotckList" :key="item.stockId"  :label="item.stockName" :value="item.stockId"  />
      </el-select>
    </div>
    <div class="boardContent">
      <component :is='item.component' v-model:formData="formData" v-for='item in tabList' v-model:isLike="item.isLike" @like='likeUnit' :key="item.timer"></component>
    </div>
  </div>
</template>

<script>
import {getCurrentInstance, onMounted, reactive, ref, shallowRef, toRefs} from 'vue'
import {Search} from "@element-plus/icons-vue";
import InStockWaning from '../components/inStockWaning.vue'
import TimeWarning from '../components/timeWarning.vue'
import InStockGoodsTop5 from '../components/goodsTop5.vue'
import InStockBoard from '../components/board.vue';
import Unsalable from '../components/unsalable.vue';
import InStockTotal from '../components/total.vue';
import moment from 'moment';
import {stockList} from '@/utils/api/procurement/stock.js'
import {customAdd, customDel, customList} from '@/utils/api/custom.js'

export default {
  name:"board",
  components:{
    Search,
    InStockWaning,
    InStockGoodsTop5,
    InStockBoard,
    InStockTotal,
    TimeWarning,
    Unsalable
  },
  setup () {
    const menuId = 41
    const { proxy } = getCurrentInstance()
    const loading = ref(false)
    const state = reactive({
      tabChecked:0,
      formData:{
        status:0,
        time:[],
        stockId:"",
      },
      shortcuts:[
        {
          text: '今日',
          value: new Date(),
        },
        {
          text: '昨日',
          value: getTime(1,'days','yesterday'),
        },
      ],
      sotckList:[],
      isLike:true
    })
    //获取仓库列表
    const getStockListData = async () => {
      const res = await stockList({
        all: false,
        status: true
      })
      if (res.code === 0) {
        if (res.data?.list?.length === 1) {
          state.formData.stockId = res.data.list[0].stockId
          state.sotckList = res.data.list
        }else if(res.data?.list?.length  && res.data?.list?.length > 1){
          state.sotckList.push({stockId:"",stockName:'全部仓库'})
          state.sotckList.push(...res.data.list)
        }
      }else{
        proxy.$message.error(res.msg)
      }
    }
    onMounted(() => {
      getStockListData()
    })
    //切换本月年度
    const tabCheck=(num)=>{
      if(num == state.tabChecked) return
      switch (num) {
        case 0:
          state.formData.time =   [moment().startOf('months').format('YYYY-MM-DD'),moment().format('YYYY-MM-DD')]
          break;
        case 1:
          state.formData.time = [moment().startOf('quarters').format('YYYY-MM-DD'),moment().format('YYYY-MM-DD')]
          break;
        case 2:
          state.formData.time =   [moment().startOf('year'),moment().format('YYYY-MM-DD')]
          break;
        default:
          break;
      }
      state.tabChecked = num
      showLoading()
    }
    function getTime (num, date,type){
      let star = ''
      let end = ''
      if(type == 'yesterday'){
        star = moment(new Date()).subtract(num, date)._d
        star = moment(star).format('yyyy-MM-DD') + ' 00:00:00'
        end = moment(new Date()).subtract(num, date)._d
        end = moment(end).format('yyyy-MM-DD') + ' 23:59:59'
      }else{
        star = moment(new Date()).format('yyyy-MM-DD')
        end = moment(new Date()).subtract(num, date)._d
        end = moment(end).format('yyyy-MM-DD')
      }
      return [end,star]
    }
    const showLoading = ()=>{
      loading.value = true
      tabList.value.forEach(item=>{
        item.timer = new Date().getTime() + item.unitId
      })
      setTimeout(()=>{
        loading.value = false
      },200)
    }
    const changeTime = (e)=>{
      state.tabChecked = -1
      if(e){
        state.formData.time=[moment(e[0]).format('yyyy-MM-DD') + ' 00:00:00',moment(e[1]).format('yyyy-MM-DD') + ' 23:59:59']
      }else{
        state.formData.time = []
      }
      showLoading()
    }
    const tabList = ref([
      {
        unitId: 14,
        isLike:false,
        component: shallowRef(InStockBoard),
        timer:0
      },
      {
        unitId: 15,
        isLike:false,
        component: shallowRef(InStockTotal),
        timer:0
      },

      {
        unitId: 16,
        isLike:false,
        component: shallowRef(TimeWarning),
        timer:0
      },
      {
        unitId: 17,
        isLike:false,
        component: shallowRef(Unsalable),
        timer:0
      },
      {
        unitId: 12,
        isLike:false,
        component: shallowRef(InStockWaning),
        timer:0
      },
      {
        unitId: 13,
        isLike:false,
        component: shallowRef(InStockGoodsTop5),
        timer:0
      },


    ])
    const likeUnit = (e)=>{
      if(!e.type){
        customDel({unitId: e.unitId, menuId}).then(res => {
          if(res.code == 0){
            tabList.value.forEach(item=>{
              if(item.unitId == e.unitId){
                item.isLike = false
              }
            })
            proxy.$message.success('取消成功')
          }else{
            proxy.$message.error(res.msg)
          }
        })
      }else{
        customAdd({unitId: e.unitId, menuId}).then(res => {
          if(res.code == 0){
            tabList.value.forEach(item=>{
              if(item.unitId == e.unitId){
                item.isLike = true
              }
            })
            proxy.$message.success('收藏成功')
            (tabList.value);
          }else{
            proxy.$message.error(res.msg)
          }
        })
      }
    }
    const getList = ()=>{
      customList().then(res=>{
        if (res.code === 0) {
          if(res?.data?.list?.length > 0) {
            let list = res.data.list
            tabList.value.forEach(item=>{
              list.forEach(i=>{
                if (item.unitId === i.unitId) {
                  item.isLike = true
                }
              })
            })
          }
        }
      })
    }
    tabCheck(0)
    onMounted(()=>{
      getList()
    })
    return {
      showLoading,
      loading,
      likeUnit,
      tabList,
      changeTime,
      tabCheck,
      ...toRefs(state),
    }
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
.select {
  margin: 0 8px;
  width: 160px;
}
</style>
